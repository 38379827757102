<template>
  <!-- 开放用房 -->
  <div class="virtualsimulation">
    <div class="search">
      <div class="leftcontain">
        <div class="left1">
          <div class="text">关键字:</div>
          <el-input class="elinput" v-model="form.keyword" placeholder="请输入内容">
            <i class="el-icon-search el-icon_search" slot="suffix"> </i>
          </el-input>
        </div>
        <div class="left2">
          <div class="text">时间:</div>
          <el-date-picker class="elinput" v-model="timevalue" type="datetimerange" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div class="rightcontain">
        <el-button type="primary" @click="searchbt" style="background:#3d84ff">查询</el-button>
        <el-button type="info" @click="resetbt">重置</el-button>
        <!-- <div class="bt " @click="resetbt">重置</div>
        <div class="bt bt1" @click="searchbt">查询</div> -->
      </div>
    </div>
    <div class="parentt">
      <div class="contain">
        <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table"
          :header-cell-style="{
            'font-size': '15px',
            color: '#666666',
            'font-weight': 'bold',
            background: '#F7F7F7',
          }" :row-style="{
            'font-size': '15px',
            color: '#222222',
            'font-weight': '400',
          }">
          <el-table-column prop="lab_room_name" label="实验用房" show-overflow-tooltip width="180">
          </el-table-column>
          <el-table-column prop="start_time" label="预约时间" show-overflow-tooltip width="380">
            <template slot-scope="scope">
              {{ scope.row.start_time | dataformat }}至{{
              scope.row.end_time | dataformat
              }}
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="提交时间" show-overflow-tooltip width="380">
            <template slot-scope="scope">
              {{ scope.row.created_at | dataformat }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="审核状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.status | statusformat }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="operatebt">
                <div class="bt" @click="viewbt(scope.row.open_room_id)">
                  查看
                </div>
                <div v-if="scope.row.status == 0" class="bt" @click="cancelbt(scope.row.open_room_id)">
                  取消预约
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="elpagination" v-if="total > 0">
          <div class="elpagination-title">共{{ total }}条</div>
          <el-pagination background layout="prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div> -->
    <Customdialog ref="customdialog" class="customdialog" width="950px" type="max" title="预约申请" :showclose="true">
      <div slot="dialogbody" class="dialogbody">
        <div class="bodycontain">
          <div class="outcontain">
            <div class="titletext">预约人信息:</div>
            <div class="itemcontain">
              <div class="itemleft">
                <div class="text">姓名:</div>
                <div class="text text2">{{ infodata.user_name }}</div>
              </div>
              <div class="itemright">
                <div class="text">类型:</div>
                <div class="text text2">
                  {{ infodata.user_identity | usertypeformat }}
                </div>
              </div>
            </div>

            <div class="itemcontain">
              <div class="itemleft">
                <div class="text">编号:</div>
                <div class="text text2">{{ infodata.user_sn }}</div>
              </div>
              <div class="itemright">
                <div class="text">院系/班级:</div>
                <div class="text text2">
                  {{
                  infodata.college_name
                  ? infodata.college_name
                  : "" + "/" + infodata.class_name
                  ? infodata.class_name
                  : ""
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="outcontain">
            <div class="titletext">预约信息:</div>
            <div class="itemcontain">
              <div class="itemleft">
                <div class="text">预约用房:</div>
                <div class="text text2">{{ infodata.lab_room_name }}</div>
              </div>
              <div class="itemright">
                <div class="text">所属实验室:</div>
                <div class="text text2">{{ infodata.lab_name }}</div>
              </div>
            </div>

            <div class="itemcontain">
              <div class="itemleft">
                <div class="text">用房地点:</div>
                <div class="text text2">{{ infodata.building_name }}</div>
              </div>
              <div class="itemright">
                <div class="text">预约要求:</div>
                <div class="text text2">{{ infodata.type | typeformat }}</div>
              </div>
            </div>

            <div class="itemcontain">
              <div class="itemleft">
                <div class="text">预约时间:</div>
                <div class="text text2">
                  {{ infodata.start_time | dataformat }} 至
                  {{ infodata.end_time | dataformat }}
                </div>
              </div>
              <div class="itemright">
                <div class="text">提交时间:</div>
                <div class="text text2">
                  {{ infodata.created_at | dataformat }}
                </div>
              </div>
            </div>

            <div class="itemcontain">
              <div class="itemleft">
                <div class="text">实验方案:</div>
                <div class="text text2">{{ infodata.plan }}</div>
              </div>
              <div class="itemright">
                <div class="text">实验说明:</div>
                <div class="text text2">{{ infodata.remark }}</div>
              </div>
            </div>
          </div>

          <div class="outcontain">
            <div class="titletext">审核信息:</div>
            <div v-if="infodata.status == 2" class="itemcontain">
              <div class="itemleft">
                <div class="text">审核通过</div>
                <div class="text text2"></div>
              </div>
            </div>
            <div v-if="infodata.status == 0" class="itemcontain">
              <div class="itemleft">
                <div class="text">待审核</div>
                <div class="text text2"></div>
              </div>
            </div>
            <div v-if="infodata.status == 3" class="itemcontain">
              <div class="itemleft">
                <div class="text">审核驳回</div>
                <div class="text text2"></div>
              </div>
              <div class="itemright">
                <div class="text">驳回理由:</div>
                <div class="text text2">{{ infodata.reason }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="infodata.status == 0" slot="dialogfooter" class="dialogfooter">
        <el-button class="bt" @click="cancelbt(infodata.open_room_id)">取消预约</el-button>
      </div>
    </Customdialog>
  </div>
</template>

<script>
const typemap = ["", "实验位", "实验室"];
const usertypemap = ["其他", "学生", "老师", "校外人员"];
const statusmap = ["待审核", "取消预约", "通过", "驳回"];
import dayjs from "dayjs";
import {
  getmyroombook,
  openroombookcancel,
  getopenroombookinfo,
} from "@/api/openroombook";
import Customdialog from "@/components/customdialog.vue";
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
  },
  data() {
    return {
      infodata: "",
      timevalue: "",
      total: 0,

      form: {
        keyword: "",
        start_time: "",
        end_time: "",
      },
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      tableData: [],
    };
  },

  filters: {
    typeformat(val) {
      return typemap[val];
    },
    usertypeformat(val) {
      return usertypemap[val];
    },
    statusformat(val) {
      return statusmap[val];
    },
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },

  created() {
    this.getmyroombook();
  },
  methods: {
    resetbt() {
      this.timevalue = "";
      this.form.keyword = "";
      this.form.start_time = "";
      this.form.end_time = "";
      this.getmyroombook();
    },
    searchbt() {
      if (this.timevalue && this.timevalue.length > 0) {
        if (this.timevalue[0]) {
          this.form.start_time = dayjs(this.timevalue[0]).unix();
        }
        if (this.timevalue[1]) {
          this.form.end_time = dayjs(this.timevalue[1]).unix();
        }
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }

      this.getmyroombook(this.form);
    },
    viewbt(id) {
      this.$refs.customdialog.dialogopenbt();
      this.getopenroombookinfo({
        id: id,
      });
    },
    cancelbt(id) {
      this.openroombookcancel({
        id: id,
      });
    },
    getopenroombookinfo(params) {
      getopenroombookinfo(params)
        .then((response) => {
          if (response.code == 0) {
            this.infodata = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getmyroombook(params) {
      this.tableData = [];
      getmyroombook(params)
        .then((response) => {
          this.tableData = response.data.data;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openroombookcancel(params) {
      openroombookcancel(params)
        .then((response) => {
          if (response.code == 0) {
            this.getmyroombook();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">

.virtualsimulation {
  .search {
    // margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;
    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;

          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 200px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;

          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 300px;
        }
      }
    }
    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;
      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        font-size: 14px;

        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        margin-left: 10px;
        background: #3d84ff;
        color: white;
      }
    }
  }
  .parentt {
    // border: 1px solid red;
    position: relative;
    // margin-top: 20px;
    // height: 600px;
    .contain {
      // border: 1px solid red;
      position: absolute;
      // width: 100%;
      // height: 100%;
      top: 0px;
      left: 0px;
      right: 0px;
      .Table {
        margin-top: 50px;
        width: 100%;
        // border: 1px solid red;

        .operatebt {
          // border: 1px solid red;
          display: flex;
          justify-content: center;
          align-items: center;

          .bt {
            // border: 1px solid red;
            margin-left: 20px;
            font-size: 15px;
            font-weight: 400;
            color: #3d84ff;
            user-select: none;
            cursor: pointer;
          }
          .bt2 {
            margin-left: 20px;
          }
        }
      }
      .elpagination {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .elpagination-title {
          margin-right: 4px;
        }
      }
    }
  }
  // .elpagination {
  //   margin-top: 40px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   .elpagination-title {
  //     margin-right: 4px;
  //   }
  // }
  .customdialog {
    // border: 1px solid red;
    .dialogbody {
      padding: 40px;
      .bodycontain {
        // border: 1px solid red;
        .outcontain {
          margin-bottom: 30px;
          .titletext {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #3a3a3a;
          }
          .itemcontain {
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .itemleft {
              display: flex;
              flex-basis: 450px;
              justify-content: flex-start;
              align-items: center;
            }
            .itemright {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
            .text {
              font-size: 16px;
              font-weight: 400;
              color: #3a3a3a;
            }
            .text2 {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .dialogfooter {
      border-top: 1px solid #dedede;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .bt {
        margin-right: 40px;
        background: #3d84ff;
        color: white;
      }
    }
  }
}
</style>
