<template>
  <el-form
    :model="ruleForm"
    status-icon
    :rules="rules"
    ref="ruleForm"
    label-position="right"
    label-width="140px"
    class="elform"
  >
    <el-form-item label="旧密码:" prop="pass0">
      <el-input
        class="elformitem"
        type="password"
        v-model="ruleForm.pass0"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="新密码:" prop="pass">
      <el-input
        class="elformitem"
        type="password"
        v-model="ruleForm.pass"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="确认新密码" prop="checkPass">
      <el-input
        class="elformitem"
        type="password"
        v-model="ruleForm.checkPass"
        autocomplete="off"
      ></el-input>
    </el-form-item>

    <!-- <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </el-form-item> -->
  </el-form>
</template>

<script>
import { edituserresetpassword } from "@/api/user";
export default {
  name: "Head",
  components: {},
  props: {
    close: {
      type: Function,
    },
  },
  data() {
    var validatePass0 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass0: "",
        pass: "",
        checkPass: "",
      },
      rules: {
        pass0: [{ required: true, validator: validatePass0, trigger: "blur" }],
        pass: [{ required: true, validator: validatePass, trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.edituserresetpassword({
            old_password: this.ruleForm.pass0,
            password: this.ruleForm.pass,
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    edituserresetpassword(params) {
      edituserresetpassword(params)
        .then((response) => {
          if (response.code == 0) {
            this.close();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.elform {
  padding: 40px;
  width: 500px;
  .elformitem {
    // width: 400px;
  }
}
</style>
