<template>
  <!-- 消息通知 -->
  <div class="main-contain">
    <div class="tab-bar">
      <el-tabs class="eltabs" v-model="activeName">
        <el-tab-pane label="系统通知" name="first">
          <div class="is-empty" style="text-align: center;margin: 15% auto;">
            <img :src="no_math">
            <div style="font-size: 24px; font-weight: bold; color: #b5a2a2;">
              <span>暂无系统通知</span>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="消息推送" name="second">
          <div class="is-empty" style="text-align: center;margin: 15% auto;">
            <img :src="no_math">
            <div style="font-size: 24px; font-weight: bold; color: #b5a2a2;">
              <span>暂无消息推送</span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="tab-right">
        <div class="item">
          <el-image class="elimage" :src="image1" />
          <div class="mark">标记全部已读</div>
        </div>
        <div class="item">
          <el-image class="elimage" :src="image2" />
          <div class="clear">清空全部消息</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "virtualsimulation",
  components: {},
  data() {
    return {
      activeName: "first",
      no_math: require('@/assets/no_math1.png'),
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/图层 64.png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      icon3: require("@/assets/coursedetails/43443.png"),
      image1: require("@/assets/coursedetails/center/信封.png"),
      image2: require("@/assets/coursedetails/center/清空.png"),
    };
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  .tab-bar {
    position: relative;
    .eltabs {
      ::v-deep .el-tabs__item {
        font-size: 16px;
        padding: 0px;
        width: 150px;
        text-align: center;
      }
    }
    .tab-right {
      position: absolute;
      right: 0px;
      top: -3px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      .item {
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;
        cursor: pointer;
        .elimage {
          width: 20px;
          height: 20px;
        }
        .mark {
          margin-left: 5px;
          font-size: 14px;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
        }
        .clear {
          margin-left: 5px;
          font-size: 14px;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
        }
      }
    }
  }
  .body-contain {
    .item1 {
      height: 123px;
      background: #ffffff;
      box-shadow: 0px 1px 0px 0px rgba(223, 223, 223, 0.92);
      box-sizing: border-box;
      padding: 20px;
      .h-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .icon {
            margin-right: 5px;
            width: 5px;
            height: 5px;
            background: #288add;
            border-radius: 50%;
          }
          .text {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #222222;
          }
        }
        .right {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .elimage {
              width: 13px;
              height: 13px;
            }
            .time {
              margin-left: 6px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #525252;
            }
          }
          .right {
            margin-left: 20px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #26b5ff;
            user-select: none;
            cursor: pointer;
          }
        }
      }
      .content {
        margin-top: 10px;
        margin-left: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }
    }
    .item2 {
      background: #f7f7f7;
    }
  }
}
</style>
