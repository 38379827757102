<template>
  <!-- 我的实习-->
  <div class="virtualsimulation">
    <div class="search">
      <div class="leftcontain">
        <div class="left2">
          <div class="text">实习形式:</div>
          <el-select style="margin-left: 10px" class="elinput" v-model="form.type" placeholder="请选择">
            <el-option v-for="item in typeoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="left2">
          <div class="text">实习状态:</div>
          <el-select style="margin-left: 10px" class="elinput" v-model="form.status" placeholder="请选择">
            <el-option v-for="item in statusoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="left1">
          <div class="text">实习时间:</div>
          <el-date-picker class="elinput" v-model="timevalue" type="datetimerange" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div class="rightcontain">
        <el-button type="primary" @click="searchbt" style="background:#3d84ff">查询</el-button>
        <el-button type="info" @click="resetbt">重置</el-button>
        <!-- <div class="bt bt1" @click="searchbt">查询</div>
        <div class="bt" @click="resetbt">重置</div> -->
      </div>
    </div>

    <div class="itemcontain">
      <template>
        <div class="item" v-for="(item, index) in datalist" :key="index">
          <div class="h-title" @click="toInternshipdetails(item)">
            <div class="text">{{ item.plan_name }}</div>
            <div class="bt">{{ item.type | typeformat }}</div>
            <div class="bt bt1" style="border: 1px solid #d9fae2">{{ item.status | statusformat }}</div>
            <div class="bt" style="background:#2bb642;color: #fff;border: 1px solid #2bb642"
              v-if="item.apply_info_status == 0">待审核</div>
            <div class="bt" style="background:#2bb642;color: #fff;border: 1px solid #2bb642"
              v-if="item.apply_info_status == 1">审核驳回</div>
            <div class="bt" style="background:#f56c6c;color: #fff;border: 1px solid #f56c6c"
              v-if="item.apply_info_status == 2">审核通过</div>
          </div>
          <div class="b-contain">
            <div class="b-left">
              <div class="item1">
                <el-image class="elimage" :src="icon1" />
                <div class="title">实践课程:</div>
                <div class="title name">{{ item.course_name }}</div>
              </div>
              <div class="item1">
                <el-image class="elimage" :src="icon2" />
                <div class="title">实习时间:</div>
                <div class="title name">
                  {{ item.start_date | dataformat }} -
                  {{ item.end_date | dataformat }}
                </div>
              </div>
              <div class="bt-contain">
                <!-- 报名中 -->
                <template v-if="item.type == 0 ">
                  <div v-if="item.allow_expired_apply == 1">
                    <div v-if=" item.apply_info_status==0" class="btgroup">
                      <!-- <div class="text">待审核</div> -->
                      <div class="bt" @click="restate(item)">重报</div>
                    </div>
                    <div v-else-if="item.apply_info_status == 1" class="btgroup">
                      <!-- <div class="text text2">审核驳回</div> -->
                      <div class="bt" @click="restate(item)">重报</div>
                    </div>
                    <div v-else-if="item.apply_info_status == 2" class="btgroup" v-show="false">
                      <div class="text">审核通过</div>
                    </div>
                    <div v-else class="bt" @click="signupname(item)">报名</div>
                  </div>
                  <div v-if="item.allow_expired_apply == 0">
                    <div v-if=" item.apply_info_status==0" class="btgroup">
                      <!-- <div class="text">待审核</div> -->
                      <div class="bt" @click="restate(item)">重报</div>
                    </div>
                    <div v-else-if="item.apply_info_status == 1" class="btgroup">
                      <!-- <div class="text text2">审核驳回</div> -->
                      <div class="bt" @click="restate(item)">重报</div>
                    </div>
                    <div v-else-if="item.apply_info_status == 2" class="btgroup" v-show="false">
                      <div class="text">审核通过</div>
                    </div>
                    <div v-else class="bt" @click="signupname(item)">报名</div>
                  </div>
                </template>
                <div class="joinin" v-if="item.apply_info_status == 2 || item.type == 1">
                  <!-- 实习中 -->
                  <template v-if="item.status == 2">
                    <div class="bt" v-if="item.day_status == 1 || item.week_status == 1 || item.month_status ==1"
                      @click.stop="logbt(item)">日志</div>
                    <div class="bt" v-if="item.sign_status == 1" @click.stop="signinbt(item)">签到</div>
                    <div class="bt" @click.stop="myleavebt(item)">请假申请</div>
                  </template>
                  <!-- 已结束 -->
                  <template v-if="item.status == 3">
                    <div class="bt" @click="logbt(item)">日志</div>
                  </template>
                </div>
              </div>
            </div>
            <div class="joinin" v-if="item.apply_info_status == 2 || item.type == 1">
              <div class="b-right" v-if="item.status == 2 || item.status == 3">
                <template v-if="item.report_commit_start_date && item.report_commit_end_date">
                  <div class="iconitem" @click="tointernshipreportbt(item)">
                    <el-image class="elimage" :src="image3" />
                    <div class="title">实习报告</div>
                  </div>
                </template>
                <div class="iconitem" @click="resultsview(item)">
                  <el-image class="elimage" :src="image1" />
                  <div class="title">实习成绩</div>
                </div>
                <div class="iconitem" @click="commitment(item,0)">
                  <el-image class="elimage" :src="image4" />
                  <div class="title">安全承诺书</div>
                </div>
                <div class="iconitem" @click="experimentcertificatebt(item)" v-if="item.certificate_url !=null">
                  <el-image class="elimage" :src="image5" />
                  <div class="title">实习证书</div>
                </div>
                <div class="iconitem" @click="commitment(item,2)">
                  <el-image class="elimage" :src="image6" />
                  <div class="title">实习单位考核表</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="10" :current-page="form.page"
        @current-change="changePage">
      </el-pagination>
    </div>

    <el-dialog :title="itemData.title" :visible.sync="dialogVisible" width="30%" append-to-body :before-close="clear">
      <div class="upload_warp">
        <div class="download_box">
          <div>{{itemData.title}}模版：</div>
          <el-button type="text" @click="download">
            模版下载 <i class="el-icon-download el-icon--right"></i>
          </el-button>
        </div>
        <div class="upload_box">
          <div>{{itemData.title}}：<span v-show="itemData.files" style="color:red">已上传</span></div>
          <el-button type="text" v-show="itemData.files" @click="seeWord(itemData.files)">
            查看 <i class="el-icon-view el-icon--right"></i>
          </el-button>
          <UploadFiles :files="fileList" :cloudType="'practice_report/url'" @setUrl="getUrl" :btnType="'text'" :btnMsg="'文件上传'" />
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="handleUpload">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog title="提示" :visible.sync="dialogVisible1" width="40%" :before-close="handleClose">
      <iframe :src="certificate_url" frameborder="0" style="width: 100%; height: 100vh"></iframe>
    </el-dialog> -->
  </div>
</template>

<script>
import { TemplateFile } from "@/api/practiceplanfreelyapplyinfo";

const typemap = ["自主报名", "集中安排"];
const statusmap = ["未开始", "报名中", "实习中", "已结束"];
import dayjs from "dayjs";
import { getmyplanlist,uploadSafetyLetter } from "@/api/practiceplanstudent";
import UploadFiles from "@/components/upload-files.vue";
export default {
  name: "virtualsimulation",
  components: {
    UploadFiles,
  },
  filters: {
    typeformat(val) {
      return typemap[val];
    },
    statusformat(val) {
      return statusmap[val];
    },
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  data() {
    return {
      timevalue: "",
      total: 0,
      form: {
        start_time: "",
        end_time: "",
        type: "",
        status: "",
        page: 1,
        per_page: 10,
      },
      optionone: [],
      optiontwo: [],
      typeoptions: [
        {
          value: 0,
          label: "自主报名",
        },
        {
          value: 1,
          label: "集中安排",
        },
      ],
      statusoptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 0,
          label: "未开始",
        },
        {
          value: 1,
          label: "报名中",
        },
        {
          value: 2,
          label: "实习中",
        },
        {
          value: 3,
          label: "已结束",
        },
      ],

      datalist: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/课程.png"),
      icon2: require("@/assets/coursedetails/center/时间 钟表.png"),
      icon3: require("@/assets/coursedetails/43443.png"),

      image1: require("@/assets/coursedetails/center/achievement.png"),
      image2: require("@/assets/coursedetails/center/图标2.png"),
      image3: require("@/assets/coursedetails/center/图标3.png"),
      image4: require("@/assets/coursedetails/center/safety_commitment.png"),
      image5: require("@/assets/coursedetails/center/certificate.png"),
      image6: require("@/assets/coursedetails/center/assessment.png"),

      is_current_item: "",
      is_current_id: "",
      dialogVisible: false,
      certificate_url:'',
      itemData: {}, // 接收列表单项数据
      fileList: [],
      icon4: require("@/assets/查看.png"),
      icon5: require("@/assets/下载.png"),
    };
  },
  created() {
    this.getmyplanlist();
  },
  methods: {
    searchbt() {
      if (this.timevalue && this.timevalue.length > 0) {
        if (this.timevalue[0]) {
          this.form.start_time = dayjs(this.timevalue[0]).unix();
        }
        if (this.timevalue[1]) {
          this.form.end_time = dayjs(this.timevalue[1]).unix();
        }
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }

      this.getmyplanlist(this.form);
    },
    changePage(val) {
      this.queryParams.page = val;
      this.getmyplanlist();
    },
    resetbt() {
      this.form.start_time = "";
      this.form.end_time = "";
      this.form.type = "";
      this.form.status = "";
      this.timevalue = "";
      this.getmyplanlist(this.form);
    },

    toInternshipdetails(item) {
      this.$router.push({
        path: "/home/personalcenter/internshipdetails",
        query: {
          practice_plan_id: item.plan_id,
          student_id: item.student_id ? item.student_id : "",
        },
      });
    },
    tointernshipreportbt(item) {
      // console.log(item, 'item')
      if (item.report_status != 2) {
        //未批阅 提交实习报告
        this.$router.push({
          path: "/home/personalcenter/submitreport",
          query: {
            status: !item.report_status ? "提交" : "编辑",
            practice_plan_id: item.plan_id,
            practice_report_id: item.practice_report_id
              ? item.practice_report_id
              : "",
            student_id: item.student_id ? item.student_id : "",
          },
        });
      }
      if (item.practice_report_status == 1) {
        //已通过 查看实习报告
        this.$router.push({
          path: "/home/personalcenter/internshipreportview",
          query: {
            type: "通过",
            practice_plan_id: item.plan_id,
            practice_report_id: item.practice_report_id,
            student_id: item.student_id ? item.student_id : "",
          },
        });
      } else if (item.practice_report_status == 2) {
        //未通过
        //查看编辑实习报告
        this.$router.push({
          path: "/home/personalcenter/internshipreportview",
          query: {
            type: "退回",
            practice_plan_id: item.plan_id,
            practice_report_id: item.practice_report_id,
            student_id: item.student_id ? item.student_id : "",
          },
        });
      }
    },
    resultsview(item) {
      // let obj4 = JSON.stringify(item);
      this.$router.push({
        path: "/home/personalcenter/internshipresultsview",
        query: {
          plan_id: item.plan_id,
          practice_report_id: item.practice_report_id,
          student_id: item.student_id
        },
      });
    },
    interactiveqabt() {
      this.$router.push({
        path: "/home/personalcenter/interactiveqa",
      });
    },
    // 安全承诺书
    commitment(item,file_type){
      this.itemData.type = item.type;
      this.itemData.plan_id = item.plan_id;
      this.itemData.file_type = String(file_type);
      if(file_type == 0){
        this.itemData.title = "安全承诺书";
        this.itemData.files = item.safety_letter;
        this.itemData.template = item.safety_letter_template;
      } 
      if(file_type == 2){
        this.itemData.title = "单位考核表";
        this.itemData.files = item.evaluation_letter;
        this.itemData.template = item.practice_evaluation_template_url;
      }
      this.dialogVisible = true;
    },
    // 上传文件
    getUrl(data) {
      console.log("上传文件的参数", data)
      this.itemData.file = data
    },
    //重报
    restate(item) {
      this.$router.push({
        path: "/home/personalcenter/editorregistrationname",
        query: {
          freely_apply_info_id: item.freely_apply_info_id,
        },
      });
    },
    //报名
    signupname(item) {
      this.$router.push({
        path: "/home/personalcenter/registrationname",
        query: {
          freely_apply_info_id: item.freely_apply_info_id,
          plan_id: item.plan_id,
          student_id: item.student_id ? item.student_id : "",
        },
      });
    },
    //日志
    logbt(item) {
      this.$router.push({
        path: "/home/personalcenter/log",
        query: {
          practice_plan_id: item.plan_id,
          day_status: item.day_status,
          week_status: item.week_status,
          month_status: item.month_status,
        },
      });
    },
    //签到
    signinbt(item) {
      this.$router.push({
        path: "/home/personalcenter/signin",
        query: {
          plan_id: item.plan_id,
        },
      });
    },
    //请假
    myleavebt(item) {
      this.$router.push({
        path: "/home/personalcenter/myleave",
        query: {
          plan_id: item.plan_id,
        },
      });
    },
    getmyplanlist(params) {
      //this.datalist = [];
      getmyplanlist(params)
        .then((response) => {
          // console.log("接收的数据",response);
          if (response.code === 0) {
            this.total = response.data.total;
            this.datalist = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 下载模板
    download() {
      let newUrl = this.itemData.template
      if(newUrl){
        let suffix = newUrl.substring(newUrl.lastIndexOf(".")+1)
        let newName = this.itemData.title+"."+suffix
        this.local(newUrl, newName)
      }else {
        this.$message({
          message: '没有该文件！',
          type: 'warning'
        });
      }
    },
    experimentcertificatebt(val){
      window.open(val.certificate_url)
    },
    local(url, name) {
      var aLink = document.createElement("a");
      aLink.style.display = "none";
      aLink.href = url;
      aLink.target = "_blank";
      aLink.setAttribute("download", name);
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink); //下载完成移除元素
    },
    // 提交文件
    handleUpload(){
      let type = this.itemData.type ? Number(this.itemData.type) : 0;
      let practice_plan_id = this.itemData.plan_id ? Number(this.itemData.plan_id) : 0;
      let params = {
        type: type ,
        practice_plan_id: practice_plan_id,
        file_type: this.itemData.file_type,
        file: this.itemData.file
      }
      console.log(params);
      uploadSafetyLetter(params)
        .then((response) => {
          if(response.code == 0) {
            console.log("上传文件",response);
            this.fileList = []
            this.itemData.file = ''
            this.getmyplanlist();
            this.dialogVisible = false;
          }
        })
    },
    // 查看文件
    seeWord(fileUrl){
      if(fileUrl.indexOf(".") == -1){
        this.$message({
          message: '没有该文件！',
          type: 'warning'
        });
        return false
      }
      // let newUrl = this.$api.baseUrl + fileUrl
      let doc_ext = fileUrl.substring(fileUrl.lastIndexOf(".")+1);
      const typeArr = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'];
      let url = ''; // 接收文件链接 
      if (typeArr.indexOf(doc_ext) !== -1) {
        // 使用微软的office online
        // url = 'http://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(fileUrl)
        url = "https://view.xdocin.com/view?src=" + encodeURIComponent(fileUrl)
      } else {
        url = fileUrl
      }
      // window.open()打开
      window.open(url,"_blank")
    },
    clear(){
      this.fileList = []
      this.itemData.file = ''
      this.dialogVisible = false
    }
  },
};
</script>
<style scoped lang="scss">
.virtualsimulation {
  .search {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;
    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .left1 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;

          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 300px;
        }
      }
      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          max-width: 200px;
        }
      }
    }
    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;
      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        margin-left: 10px;
        margin-right: 10px;
        background: #3d84ff;
        color: white;
      }
    }
  }
  .itemcontain {
    margin-top: 17px;
    .item {
      margin-top: 30px;
      padding: 30px;
      height: 247px;
      background: #ffffff;
      box-sizing: border-box;
      border-bottom: 1px solid #f7f7f7;
      .h-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        .text {
          font-size: 25px;
          font-weight: 400;
          color: #222222;
        }
        .bt {
          margin-left: 10px;
          width: 66px;
          height: 27px;
          line-height: 27px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          font-size: 15px;
          font-weight: 400;
          color: #222222;
          user-select: none;
          cursor: pointer;
        }
        .bt1 {
          background: #d9fae2;
          color: #2fbd56;
        }
      }
      .b-contain {
        // border: 1px solid red;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .b-left {
          .item1 {
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .elimage {
              width: 22px;
              height: 20px;
            }
            .title {
              margin-left: 10px;
              margin-right: 10px;
              font-size: 20px;
              font-weight: 400;
              color: #666666;
            }
            .name {
            }
          }
          .bt-contain {
            margin-top: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .btgroup {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .text {
                font-size: 16px;
                font-weight: 400;
                color: #2bb642;
              }
              .text2 {
                color: red;
              }
              .bt {
                margin-left: 10px;
                width: 120px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                background: #3d84ff;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 400;
                color: #ffffff;
                user-select: none;
                cursor: pointer;
              }
            }
            .bt {
              margin-right: 10px;
              width: 88px;
              height: 42px;
              line-height: 42px;
              text-align: center;
              background: #3d84ff;
              border-radius: 4px;
              font-size: 18px;
              font-weight: 400;
              color: #ffffff;
              user-select: none;
              cursor: pointer;
            }
            .bt2 {
              background: #47e1b9;
            }
            .bt3 {
              background: #fa73c0;
            }
          }
        }
        .b-right {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          .linel {
            margin-left: 40px;
            margin-right: 40px;
            height: 120px;
            border-right: 1px solid #e7e7e7;
          }
          .iconitem {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: 64px;
            .elimage {
              text-align: center;
              width: 57px;
              height: 57px;
              // background: linear-gradient(126deg, #60affe 0%, #6a7cf2 100%);
              border-radius: 50%;
            }
            .title {
              user-select: none;
              font-size: 18px;
              font-weight: 400;
              color: #666666;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
   .elpagination {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    .elpagination-title {
      margin-right: 4px;
    }
  }
}
.upload_warp {
  width: 300px;
  // display: flex;
  // justify-content: space-evenly;
  .download_box {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .upload_box {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  ::v-deep .el-upload-list {
    position: absolute;
  }
}
.joinin {
  display: flex;
  justify-content: flex-start;
}
</style>
