import request from '@/utils/request'

//开放用房预约
export function openroombook(data) {
    return request({
        url: '/portal/open-room-book/book',
        method: 'POST',
        data
    })
}

//开放用房取消预约
export function openroombookcancel(data) {
    return request({
        url: '/portal/open-room-book/cancel',
        method: 'POST',
        data
    })
}

//获取开放实验用房预约列表 也可以获取用房安排列表
export function openroombookgetList(params) {
    return request({
        url: '/portal/open-room-book/get-list',
        method: 'GET',
        params
    })
}

//获取开放实验用房预约详情
export function getopenroombookinfo(params) {
    return request({
        url: '/portal/open-room-book/get-info',
        method: 'GET',
        params
    })
}
//获取开放用房预约的日历视图
export function openroombookgetdataList(params) {
    return request({
        url: '/portal/open-room-book/get-date-list',
        method: 'GET',
        params
    })
}

//获取我预约的开放用房
export function getmyroombook(params) {
    return request({
        url: '/portal/open-room-book/get-my-room-book',
        method: 'GET',
        params
    })
}