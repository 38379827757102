<template>
  <!-- 开放用房预约 -->
  <div class="virtualsimulation">
    <div class="search">
      <div class="leftcontain">
        <div class="left1">
          <div class="text">状态:</div>
          <el-input class="elinput" v-model="searchvalue" placeholder="请输入内容">
            <i class="el-icon-search el-icon_search" slot="suffix"> </i>
          </el-input>
        </div>
        <div class="left2">
          <div class="text">时间:</div>
          <el-date-picker class="elinput" v-model="timevalue" type="datetimerange" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div class="rightcontain">
        <div class="bt bt1">重置</div>
        <div class="bt">查询</div>
      </div>
    </div>
    <div class="parentt">
      <div class="contain">
        <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table"
          :header-cell-style="{
            'font-size': '15px',
            color: '#666666',
            'font-weight': 'bold',
            background: '#F7F7F7',
          }" :row-style="{
            'font-size': '15px',
            color: '#222222',
            'font-weight': '400',
          }">
          <el-table-column prop="date" label="日期"> </el-table-column>
          <el-table-column prop="time1" label="节次"> </el-table-column>
          <el-table-column prop="time2" label="实验用房"> </el-table-column>
          <el-table-column prop="address" label="地址"> </el-table-column>
          <el-table-column prop="time1" label="提交时间"> </el-table-column>
          <el-table-column prop="state" label="状态"> </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="item-right">
                <div class="bt">取消</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="elpagination">
          <div class="elpagination-title">共{{ total }}条</div>
          <el-pagination background layout="prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div> -->

    <Customdialog ref="customdialog" title="实验成绩" :showclose="true">
      <div slot="dialogbody" class="dialogbody"></div>
      <div slot="dialogfooter" class="dialogfooter"></div>
    </Customdialog>
  </div>
</template>

<script>
import Customdialog from "@/components/customdialog.vue";
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
  },
  data() {
    return {
      timevalue: "",
      total: 0,
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      tableData: [
        {
          date: "星期一",
          time1: "第一节",
          time2: "金融工程实验室",
          address: "金融工程实验室",
          time1: "2021-22-23",
          state: "待审核",
        },
      ],
    };
  },
  methods: {
    resultsbt() {
      this.$refs.customdialog.dialogopenbt();
    },
    reportbt() {
      this.$router.push({
        path: "/personalcenter/experimentalreport",
      });
    },

    interactiveqabt() {
      this.$router.push({
        path: "/personalcenter/interactiveqa",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.virtualsimulation {
  .search {
    // margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;
    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 200px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 300px;
        }
      }
    }
    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;
      .bt {
        width: 64px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #288add;
        border-radius: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #288add;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        margin-right: 10px;
        background: #26b5ff;
        color: white;
      }
    }
  }
  .parentt {
    position: relative;
    margin-top: 50px;
    .contain {
      // border: 1px solid red;
      position: absolute;
      // width: 100%;
      // height: 100%;
      top: 0px;
      left: 0px;
      right: 0px;
      .Table {
        // margin-top: 50px;
        // width: 100%;
        // border: 1px solid red;
        .item-left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // margin-left: 10px;
          .title {
            margin-left: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
        }
        .item-middle {
          .title {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
        }
        .bt {
          //   margin-right: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #26b5ff;
          user-select: none;
          cursor: pointer;
          padding: 0px;
          margin: 0px;
        }

        .item-right {
          display: flex;
          justify-content: center;
          align-items: center;
          .bt {
            //   margin-right: 10px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #26b5ff;
            user-select: none;
            cursor: pointer;
          }
          .line2 {
            margin-right: 8px;
            margin-left: 8px;
            height: 14px;
            border-right: 2px solid #cccccc;
          }
        }
      }
      .elpagination {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .elpagination-title {
          margin-right: 4px;
        }
      }
    }
  }
  // .elpagination {
  //   margin-top: 40px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   .elpagination-title {
  //     margin-right: 4px;
  //   }
  // }
}
</style>
