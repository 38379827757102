<template>
<!-- 安全准入 -->
  <div class="card">
    <el-tabs v-model="activeName" class="eltabs" @tab-click="tabHandleClick">
      <el-tab-pane label="成绩查询" name="first">
        <Tableone ref="table1" :academyid="academyid" />
      </el-tab-pane>
      <el-tab-pane label="我的证书" name="second">
        <Tabletwo ref="table2" :academyid="academyid" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Tableone from "./tableone.vue";
import Tabletwo from "./tabletwo.vue";
export default {
  name: "Home",
  components: {
    Tableone,
    Tabletwo,
  },

  data() {
    return {
      activeName: "first",
      academyid: 0,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    tabHandleClick(tab) {
      // console.log(this.activeName);
      if (this.activeName === "first") {
        // this.$refs.table1.getclassifylist();
        // this.$refs.table1.initList(this.academyid);
      } else if (this.activeName === "second") {
        // this.$refs.table2.getclassifylist();
        // this.$refs.table2.initList(this.academyid);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  //   width: calc(100% - 230px);
  padding: 10px;

  .eltabs {
    // margin-top: 40px;
    ::v-deep .el-tabs__item {
      font-size: 16px;
      padding: 0px;
      width: 150px;
      text-align: center;
    }
  }
}

::v-deep th.is-leaf {
  background: #e7ecf9;
  box-shadow: 0px 1px 0px 0px #e8e8e8;
}
// ::v-deep th.is-leaf {
//   background: #e7ecf9;
//   box-shadow: 0px 1px 0px 0px #e8e8e8;

// }
</style>
