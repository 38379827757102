import request from '@/utils/request'

//修改类型字典
export function edittypeconfig(data) {
    return request({
        url: '/portal/type-config/edit',
        method: 'POST',
        data
    })
}

//删除类型字典
export function deletetypeconfig(data) {
    return request({
        url: '/portal/type-config/delete',
        method: 'POST',
        data
    })
}

//新增类型字典
export function addtypeconfig(data) {
    return request({
        url: '/portal/type-config/add',
        method: 'POST',
        data
    })
}


//获取类型字典分类列表
export function getclassifylist(params) {
    return request({
        url: '/portal/type-config/get-classify-list',
        method: 'GET',
        params
    })
}

//获取类型字典列表
export function gettypeconfiglist(params) {
    return request({
        url: '/portal/type-config/get-list',
        method: 'GET',
        params
    })
}

//常见问题列表
export function getProblemList(params) {
    return request({
        url: '/portal/problem/get-list',
        method: 'GET',
        params
    })
}

//常见问题详情
export function getProblemInfo(params) {
    return request({
        url: '/portal/problem/get-info',
        method: 'GET',
        params
    })
}