<template>
  <!-- 我的证书-->
  <div class="virtualsimulation">
    <div class="search">
      <div class="leftcontain">
        <div class="left1">
          <div class="text">关键字:</div>
          <el-input size="small" v-model="inputvalue" placeholder="请输入证书名称"></el-input>
        </div>
      </div>
      <div class="rightcontain">
        <div class="bt bt1">重置</div>
        <div class="bt">查询</div>
      </div>
    </div>

    <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table" stripe
      :header-cell-style="{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#F7F7F7',
      }" :row-style="{
  'font-size': '15px',
  color: '#222222',
  'font-weight': '400',
}">
      <el-table-column prop="room" label="证书编号"> </el-table-column>
      <el-table-column prop="time1" label="证书名称"> </el-table-column>
      <el-table-column prop="time2" label="获得时间"> </el-table-column>
      <el-table-column prop="state" label="有效期"> </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="item-right" @click="resultsbt">
            <div class="bt">查看证书</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>

    <!-- <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total">
      </el-pagination>
    </div> -->

    <Customdialog ref="customdialog" class="customdialog" type="form" title="证书查看" :showclose="true">
      <div slot="dialogbody" class="dialogbody">
        <el-image class="elimage" :src="imagelog" />
        <div class="title">理学院实验室安全考试合格证书</div>
        <div class="line"></div>
        <div class="textcontain mgleft">
          &nbsp;&nbsp;&nbsp;&nbsp;姓名:<span class="textbold">吴靖</span>,
          学（工）号:<span class="textbold">010168</span>,经参加<span class="textbold">理学院实验室安全</span>考试,
        </div>
        <div class="textcontain">顺利通过考核，特发此证!</div>
        <div class="bottomcontain">
          <div>
            <div class="text">发证时间: &nbsp;&nbsp;</div>
            <div class="text">证书编号: &nbsp;&nbsp;</div>
          </div>
          <div class="text2">华中农业大学理学院</div>
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <el-button class="bt" size="small" @click="">下载</el-button>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import Customdialog from "@/components/customdialog.vue";
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
  },
  data() {
    return {
      inputvalue: "",
      timevalue: "",
      total: 0,
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      imagelog: require("@/assets/logo.png"),
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      tableData: [
        {
          room: "金融工程实验室1",
          time1: "2021-6-23",
          time2: "2021-86-23",
          state: "待审核",
        },
        {
          room: "金融工程实验室1",
          time1: "2021-6-23",
          time2: "2021-86-23",
          state: "待审核",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
    };
  },
  methods: {
    resultsbt() {
      this.$refs.customdialog.dialogopenbt();
    },
    reportbt() {
      this.$router.push({
        path: "/personalcenter/experimentalreport",
      });
    },

    interactiveqabt() {
      this.$router.push({
        path: "/personalcenter/interactiveqa",
      });
    },
  },
};
</script>
<style scoped lang="scss">
.virtualsimulation {
  .search {
    // margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;

    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
          white-space: nowrap;
        }

        .elinput {
          width: 200px;

          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
          white-space: nowrap;
        }

        .elinput {
          width: 300px;
        }
      }
    }

    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;

      .bt {
        width: 64px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #288add;
        border-radius: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #288add;
        user-select: none;
        cursor: pointer;
      }

      .bt1 {
        margin-right: 10px;
        background: #26b5ff;
        color: white;
      }
    }
  }

  .Table {
    margin-top: 50px;
    width: 100%;

    // border: 1px solid red;
    .item-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // margin-left: 10px;
      .title {
        margin-left: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }

    .item-middle {
      .title {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }

    .bt {
      //   margin-right: 10px;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #26b5ff;
      user-select: none;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
    }

    .item-right {
      display: flex;
      justify-content: center;
      align-items: center;

      .bt {
        //   margin-right: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #26b5ff;
        user-select: none;
        cursor: pointer;
      }

      .line2 {
        margin-right: 8px;
        margin-left: 8px;
        height: 14px;
        border-right: 2px solid #cccccc;
      }
    }
  }

  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .elpagination-title {
      margin-right: 4px;
    }
  }

  .customdialog {
    .dialogbody {
      position: relative;

      .elimage {
        margin-left: 40px;
        margin-top: 40px;
      }

      .title {
        margin-top: 20px;
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        color: #222222;
      }

      .line {
        margin: 20px;
        border-bottom: 2px solid #d6d6d6;
      }

      .textcontain {
        margin-left: 30px;
        font-size: 20px;
        line-height: 40px;
        font-weight: 500;
        color: #222222;

        .textbold {
          font-weight: bold;
        }
      }

      .mgleft {
        margin-left: 200px;
      }

      .bottomcontain {
        // position: absolute;
        margin: 20px;
        margin-top: 280px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
          font-size: 16px;
          line-height: 40px;
          font-weight: 500;
          color: #222222;
        }

        .text2 {
          text-align: center;
          width: 160px;
          font-size: 26px;
          line-height: 40px;
          font-weight: 500;
          color: #222222;
        }
      }
    }

    .dialogfooter {
      // border: 1px solid red;
      padding-top: 10px;
      padding-right: 30px;

      .bt {
        //   margin-right: 10px;
        height: 42px;
        width: 88px;
        font-size: 15px;
        background: #26b5ff;
        font-weight: 400;
        color: white;
        user-select: none;
        cursor: pointer;
        padding: 0px;
        margin: 0px;
      }
    }
  }
}
</style>
