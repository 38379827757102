<template>
  <!-- 开放实验 -->
  <div class="card">
    <el-tabs v-model="activeName" class="eltabs">
      <el-tab-pane label="开放实验安排" name="first">
        <Tableone ref="table1" :academyid="academyid" v-if="activeName=='first'" />
      </el-tab-pane>
      <el-tab-pane label="预约记录" name="second">
        <Tabletwo ref="table2" :academyid="academyid" v-if="activeName=='second'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Tableone from "./tableone.vue";
import Tabletwo from "./tabletwo.vue";
export default {
  name: "Home",
  components: {
    Tableone,
    Tabletwo,
  },

  data() {
    return {
      activeName: "first",
      academyid: 0,
    };
  },
  computed: {},
  created() {

  },
  mounted() { },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
.card {
  padding: 10px;
  .eltabs {
    ::v-deep .el-tabs__item {
      font-size: 16px;
      padding: 0px;
      width: 150px;
      text-align: center;
    }
  }
}
</style>
