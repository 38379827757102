var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"virtualsimulation"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"leftcontain"},[_c('div',{staticClass:"left1"},[_c('div',{staticClass:"text"},[_vm._v("状态:")]),_c('el-input',{staticClass:"elinput",attrs:{"placeholder":"请输入内容"},model:{value:(_vm.searchvalue),callback:function ($$v) {_vm.searchvalue=$$v},expression:"searchvalue"}},[_c('i',{staticClass:"el-icon-search el-icon_search",attrs:{"slot":"suffix"},slot:"suffix"})])],1),_c('div',{staticClass:"left2"},[_c('div',{staticClass:"text"},[_vm._v("时间:")]),_c('el-date-picker',{staticClass:"elinput",attrs:{"type":"datetimerange","range-separator":"-","start-placeholder":"开始日期","end-placeholder":"结束日期"},model:{value:(_vm.timevalue),callback:function ($$v) {_vm.timevalue=$$v},expression:"timevalue"}})],1)]),_vm._m(0)]),_c('div',{staticClass:"parentt"},[_c('div',{staticClass:"contain"},[_c('el-table',{ref:"singleTable",staticClass:"Table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"highlight-current-row":"","header-cell-style":{
          'font-size': '15px',
          color: '#666666',
          'font-weight': 'bold',
          background: '#F7F7F7',
        },"row-style":{
          'font-size': '15px',
          color: '#222222',
          'font-weight': '400',
        }}},[_c('el-table-column',{attrs:{"prop":"date","label":"日期"}}),_c('el-table-column',{attrs:{"prop":"time1","label":"节次"}}),_c('el-table-column',{attrs:{"prop":"time2","label":"实验用房"}}),_c('el-table-column',{attrs:{"prop":"address","label":"地址"}}),_c('el-table-column',{attrs:{"prop":"time1","label":"提交时间"}}),_c('el-table-column',{attrs:{"prop":"state","label":"状态"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"bt"},[_vm._v("取消")])])]}}])})],1),_c('div',{staticClass:"elpagination"},[_c('div',{staticClass:"elpagination-title"},[_vm._v("共"+_vm._s(_vm.total)+"条")]),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total}})],1)],1)]),_c('Customdialog',{ref:"customdialog",attrs:{"title":"实验成绩","showclose":true}},[_c('div',{staticClass:"dialogbody",attrs:{"slot":"dialogbody"},slot:"dialogbody"}),_c('div',{staticClass:"dialogfooter",attrs:{"slot":"dialogfooter"},slot:"dialogfooter"})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rightcontain"},[_c('div',{staticClass:"bt bt1"},[_vm._v("重置")]),_c('div',{staticClass:"bt"},[_vm._v("查询")])])}]

export { render, staticRenderFns }