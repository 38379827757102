<template>
  <!-- 考勤记录 -->
  <div class="virtualsimulation">
    <div class="search">
      <div class="leftcontain">
        <div class="left1">
          <div class="text">关键字:</div>
          <el-input class="elinput" size="medium" v-model="queryParams.keyword" placeholder="请输入">
            <i class="el-icon-search el-icon_search" slot="suffix"> </i>
          </el-input>
        </div>
        <div class="left2">
          <div class="text">考勤状态:</div>
          <el-select class="elinput" size="medium" v-model="queryParams.status" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="rightcontain">
        <el-button class="bt bt1" @click="reset">重置</el-button>
        <el-button class="bt" @click="search">查询</el-button>
      </div>
    </div>

    <el-table ref="singleTable" :data="tableData" v-loading="loading" highlight-current-row style="width: 100%"
      class="Table" :header-cell-style="{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#F7F7F7',
      }" :row-style="{
        'font-size': '15px',
        color: '#222222',
        'font-weight': '400',
      }">
      <el-table-column prop="course_lib_name" label="课程名称" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="experiment_name" label="实验名称" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="week_number" label="周次" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="day" label="星期" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.day | dayformat }}
        </template>
      </el-table-column>
      <el-table-column prop="lesson" label="节次" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="address" label="实验地点" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="status" label="考勤状态" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          {{ scope.row.status | statusformat }}
        </template>
      </el-table-column>
    </el-table>
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
        :current-page="queryParams.page" @current-change="changePage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
const statusmap = ["正常", "旷课", "迟到", "早退", "", "迟到并早退"];
const daymap = ["日", "一", "二", "三", "四", "五", "六"];
import { getmyattendancelist } from "@/api/courselibweeklylesson";

export default {
  name: "virtualsimulation",
  components: {

  },
  filters: {
    statusformat(val) {
      if (val == null) {
        return "-";
      } else {
        return statusmap[val];
      }
    },
    dayformat(val) {
      return daymap[val];
    },
  },
  data() {
    return {
      total: 0,
      tableData: [],
      queryParams: {
        keyword: "",
        status: "",
        course_lib_id: '',
        semester_id: '',
        page: 1,
        per_page: 10
      },
      options: [
        {
          value: 0,
          label: "正常",
        },
        {
          value: 1,
          label: "旷课",
        },
        {
          value: 2,
          label: "迟到",
        },
        {
          value: 3,
          label: "早退",
        },
        {
          value: 4,
          label: "迟到并早退",
        },
      ],
      loading: false
    };
  },
  methods: {
    reset() {
      this.queryParams.keyword = ''
      this.queryParams.status = ''
      this.queryParams.page = 1
      this.getmyattendancelist();
    },
    search() {
      this.getmyattendancelist();
    },
    changePage(val) {
      this.queryParams.page = val
      this.getmyattendancelist()
    },
    initdata(item, semester_id) {
      this.queryParams.course_lib_id = item.id
      this.queryParams.semester_id = semester_id;
      this.getmyattendancelist();
    },
    getmyattendancelist() {
      this.loading = true
      this.tableData = []
      getmyattendancelist(this.queryParams)
        .then((response) => {
          this.tableData = response.data.data;
          this.total = response.data.total;
          this.loading = false
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">



.virtualsimulation {
  .search {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;
    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 220px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elselect {
          width: 100px;
        }
      }
    }
    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;
      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        padding: 0px;
        background: #3D84FF;
        color: white;
        font-size: 14px;
      }
      .bt1 {
        margin-right: 10px;
        background: #ffffff;
        color: #3D84FF;
      }
    }
  }

  .Table {
    padding: 10px;
    margin-top: 30px;
    width: 100%;
    .bt {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3D84FF;
      user-select: none;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
    }
  }
  .elpagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .elpagination-title {
      margin-right: 4px;
    }
  }
}
</style>
