<template>
  <!-- 我的收藏-->
  <div class="virtualsimulation">
    <div class="search">
      <el-radio-group v-model="queryParams.type" @change="radiochange">
        <el-radio :label="-1">全部</el-radio>
        <el-radio :label="0">虚拟仿真</el-radio>
        <el-radio :label="1">实验课程</el-radio>
      </el-radio-group>
    </div>
    <div class="image-contain">
      <div class="image-item" v-if="collectlist && collectlist.length > 0">
        <div class="image-item-item" @click="todetail(item)" v-for="(item, index) in collectlist" :key="index">
          <div class="card-contain">
            <el-image class="elimage" :src="item.cover" />
            <div class="card-title">{{ item.name }}</div>
            <div class="card-footer">
              <div class="icon-title">
                {{
                item.teacher_user_info
                ? item.teacher_user_info.name
                : ""
                }}
              </div>
              <div class="icon-text">{{ item.join_count }}人参与</div>
            </div>
            <div class="icon-text" @click.stop="open(item)">
              <el-image class="icon" :src="image1" />
              <div class="text">取消收藏</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="elpagination" v-if="total > 0">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
        :current-page="queryParams.page" @current-change="changePage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Customdialog from "@/components/customdialog.vue";
import { getuserfavoritelist, setuserfavorite } from "@/api/userfavorite";
import { getcourseinfo } from "@/api/course";
export default {
  name: "mycollection",
  components: {
    Customdialog,
  },
  data() {
    return {
      collectlist: [],
      queryParams: {
        type: -1,
        page: 1,
        per_page: 10,
      },
      total: 0,
      image1: require("@/assets/coursedetails/center/收藏1.png"),
    };
  },
  created() {
    this.getuserfavoritelist();
  },
  methods: {
    tocoursedetail() {
      this.$router.push({
        path: "/wisdompracticeteaching/coursedetails",
      });
    },
    resultsbt() {
      this.$refs.customdialog.dialogopenbt();
    },
    radiochange(val) {
      this.queryParams.type = val;
      this.getuserfavoritelist();
    },
    changePage(val) {
      this.queryParams.page = val;
      this.getuserfavoritelist();
    },
    getuserfavoritelist() {
      getuserfavoritelist(this.queryParams)
        .then((response) => {
          this.collectlist = response.data.data;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    todetail(item) {
      if (item.type == 0) {
        window.localStorage.setItem("menuId", 1);
        this.$store.dispatch("setmenuid", 1);
        this.$router.push({
          path: "/home/virtualsimulationexperiment/experimentdetails",
          query: {
            id: item.id,
          },
        });
      } else {
        window.localStorage.setItem("menuId", 2);
        this.$store.dispatch("setmenuid", 2);
        if (item.is_join == 1) {
          //已经加入课程
          this.getcourseinfo({
            course_sn: item.course_sn,
          });
        } else {
          this.$router.push({
            path: "/home/wisdompracticeteaching/coursedetails",
            query: {
              course_sn: item.course_sn,
            },
          });
        }
      }
    },
    getcourseinfo(params) {
      getcourseinfo(params)
        .then((response) => {
          if (response.code == 0) {
            let course_sn = response.data.course_sn;
            this.$router.push({
              path: "/home/wisdompracticeteaching/courselearning",
              query: {
                course_sn: course_sn,
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setuserfavorite(params) {
      setuserfavorite(params)
        .then((response) => {
          if (response.code == 0) {
            this.getuserfavoritelist();
            this.$message({
              type: "success",
              message: "取消收藏成功!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    open(item) {
      this.$confirm("是否确认取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setuserfavorite({
            id: item.id,
            type: item.type,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消取消收藏",
          });
        });
    },
  },
};
</script>
<style scoped lang="scss">
.virtualsimulation {
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin-left: 10px;
    .check-group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .check-all {
        margin-right: 20px;
      }
      .elinput {
        margin-left: 20px;
        width: 300px;
        ::v-deep .el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .elinput {
      width: 300px;
      ::v-deep .el-input__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .image-contain {
    margin-top: 10px;
    .image-item {
      margin-bottom: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .image-item-item {
        border: 1px solid red;
        margin-bottom: 10px;
        border: 1px solid transparent;
        flex-basis: 330px;
        box-sizing: border-box;
        padding: 10px;
        cursor: pointer;
        &:hover {
          border: 1px solid rgb(8, 225, 233);
        }
        .card-contain {
          background: #ffffff;
          border-radius: 4px;

          .elimage {
            width: 100%;
            height: 200px;
          }
          .card-title {
            margin-top: 10px;
            margin-left: 10px;
            font-size: 15px;
            width: 100%;
            font-weight: 500;
            color: #222222;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .card-footer {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon-title {
              margin-left: 10px;
              font-size: 15px;
              font-weight: 400;
              color: #666666;
            }
            .icon-text {
              background-image: url(~@/assets/矩形图标.png);
              width: 108px;
              height: 24px;
              text-align: center;
              color: #288add;
            }
          }
          .icon-text {
            margin-top: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 34px;
            background: #e9f4ff;
            .icon {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .elpagination-title {
      margin-right: 4px;
    }
  }
}
</style>
