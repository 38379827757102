<template>
  <div class="yulan">
    <div v-if="showDoc" class="word">
      <iframe :src="wordUrl + file" width="100%" height="100%" frameborder="0" />
    </div>
    <div v-else-if="showPdf" class="pdf">
      <embed :src="file" style="width:100%;height:100%" />
    </div>
    <div v-else-if="showImg" class="imag">
      <img :src="file" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["url", "type"],
  data() {
    return {
      wordUrl: "https://view.officeapps.live.com/op/view.aspx?src=",
      // wordUrl: "http://vw.usdoc.cn/?src=",
      //  wordUrl: 'http://view.xdocin.com/xdoc?_xdoc=',
      file: "",
      showDoc: false,
      showPdf: false,
      showImg: false,
      wordExcl: ["doc", "docx", 'dotx', "xls", "xlsx", 'xlsb', 'xlsm', 'pptx', 'ppsx', 'ppt', 'pps', 'potx', 'ppsm'],
      imgs: ["jpg", "png", "jpeg", "gif"]
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const type = this.type;
      this.file = this.url;
      if (this.wordExcl.includes(type)) {
        this.showDoc = true;
      } else if (type.indexOf("pdf") != -1) {
        this.showPdf = true;
      } else if (this.imgs.includes(type)) {
        this.showImg = true;
      } else {
        this.$message.error("此文件无法预览");
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.yulan {
  height: 600px;
  overflow-x: hidden;
  .word,
  .pdf {
    width: 100%;
    height: 100%;
  }
}
</style>
