var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"virtualsimulation"},[_c('el-table',{ref:"singleTable",staticClass:"Table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"highlight-current-row":"","header-cell-style":{
      'font-size': '15px',
      color: '#666666',
      'font-weight': 'bold',
      background: '#F7F7F7',
    },"row-style":{
      'font-size': '15px',
      color: '#222222',
      'font-weight': '400',
    }}},[_c('el-table-column',{attrs:{"prop":"preview_name","label":"资料名称","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"course_lib_sn","label":"课程编号","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"course_lib_name","label":"课程名","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"experiment_name","label":"实验项目","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"bt",on:{"click":function($event){return _vm.look(scope.row)}}},[_vm._v("查看")])])]}}])})],1),_c('div',{staticClass:"elpagination"},[_c('div',{staticClass:"elpagination-title"},[_vm._v("共"+_vm._s(_vm.total)+"条")]),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"page-size":10,"current-page":_vm.queryParams.page},on:{"current-change":_vm.changePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }